import { MvContinent } from '@/lib/strapi-types/MvContinent';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindOneContinentProps {
  filters: Record<string, unknown>;
  populate?: string[];
}

export async function useFindOneContinent({ filters, populate = [] }: UseFindOneContinentProps) {
  const defaultPopulate = ['media'];
  const allPopulate = [...defaultPopulate, ...populate];

  const parsedQuery = stringify(
    {
      populate: allPopulate,
      filters,
      pagination: {
        start: 0,
        limit: 1
      }
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvContinent['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.CONTINENTS)}?${parsedQuery}`
  );

  return { continent: data.value?.data[0] };
}
